@import "@vahak/core/dist/scss/variables";
@import "@vahak/core/dist/scss/functions";

// Desktop First
// using max-width

.sectionHeading {
    margin-bottom: 10px;
}

.uploadRcWrapper {
    width: 100%;
}

//Desktop Screen only
@media (min-width: $desktopBreakpoint) {
}

// Mid screen
@media (min-width: $mobileBreakpoint) and (max-width: $desktopBreakpoint) {
}

//Small Screen only
@media (max-width: $mobileBreakpoint) {
}
