@import "@vahak/core/dist/scss/variables";
@import "@vahak/core/dist/scss/functions";

.buttonWrapper {
    display: grid;
    column-gap: 20px;
    grid-template-columns: repeat(2, max-content);

    button {
        width: 100%;
        padding-inline: calcRem(46);
        display: inline-flex;
        align-items: center;
    }

    .buttonIconWrapper {
        svg {
            height: 16px;
            width: 16px;
            margin-right: 4px;
        }
        svg,
        path {
            fill: white;
        }
        margin-right: 4px;
    }
}

//Small Screen only
@media (max-width: $mobileBreakpoint) {
    .buttonWrapper {
        column-gap: 12px;
        width: 100%;
        grid-template-columns: auto minmax(max-content, auto);
        button {
            width: 100%;
            padding-inline: calcRem(20);
        }
    }
}
