@import "@vahak/core/dist/scss/variables";
@import "@vahak/core/dist/scss/functions";

@mixin thumbStyle {
    /* removing default appearance */
    -webkit-appearance: none;
    appearance: none;
    /* creating a custom design */
    width: 28px;
    height: 42px;
    margin-top: 25px;
    background: url('data:image/svg+xml,<svg width="28" height="42" viewBox="0 0 28 42" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="13" width="2" height="25" rx="1" fill="%23383838"/><path d="M13.134 15.5C13.5189 14.8333 14.4811 14.8333 14.866 15.5L25.2583 33.5C25.6432 34.1667 25.1621 35 24.3923 35H3.60769C2.83789 35 2.35677 34.1667 2.74167 33.5L13.134 15.5Z" fill="%23383838"/><path d="M11 24H16.8438M11.974 24C12.3577 24 12.7376 24.0806 13.0921 24.2372C13.4466 24.3939 13.7687 24.6234 14.04 24.9129C14.3114 25.2023 14.5266 25.5458 14.6734 25.924C14.7616 26.1722 14.8851 26.8269 14.6734 27.4594C14.5266 27.8375 14.3114 28.1811 14.04 28.4705C13.7687 28.7599 13.4466 28.9895 13.0921 29.1461C12.7376 29.3027 12.3577 29.3833 11.974 29.3833H11L13.9219 32.5M11 26.55H16.8438" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>');
    transition: 0.5s ease-in-out;
}

.main {
    width: 100%;
    .inputContainer {
        position: relative;
        width: 100%;
        height: 50px;

        input[type="range"].sliderInput {
            &:not(:disabled) {
                cursor: pointer;
            }
            /* removing default appearance */
            -webkit-appearance: none;
            appearance: none;
            /* creating a custom design */
            width: 100%;
            outline: none;

            border-radius: 4px;
            height: 6px;
            background: linear-gradient(to right, $red 0%, yellow 40%, $green 100%);

            /* Thumb: webkit */
            &::-webkit-slider-thumb {
                @include thumbStyle;
            }
            &::-moz-range-thumb {
                @include thumbStyle;
            }
        }
    }

    .statusContainer {
        display: inline-flex;
        gap: 4px;
        font-size: calcRem(12);
        font-family: var(--font-medium);
        text-wrap: wrap;
        align-items: center;
    }
}
