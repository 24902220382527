@import "@vahak/core/dist/scss/variables";
@import "@vahak/core/dist/scss/functions";
@import "@vahak/core/dist/scss/mixins";

.main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 4px;

    .locations {
        display: flex;
        flex-direction: column;

        .location {
            display: grid;
            grid-template-columns: max-content auto;
            column-gap: 10px;
            position: relative;

            .info {
                margin-bottom: 8px;
                &.ellipses {
                    @include textEllipsis(2);
                }
            }

            .icon {
                z-index: 0;
                // svg {
                //     width: 14px;
                //     height: 14px;
                // }
            }

            &::after {
                content: "";
                display: block;
                height: calc(100% - 21px);
                z-index: 0;
                position: absolute;
                left: 10px;
                top: 23px;
                border-right: 2px solid $grey-200;
                transform: translateX(-50%);
            }
            &:nth-last-of-type(1) {
                &::after {
                    display: none;
                }
            }
        }
    }
}
