@import "@vahak/core/dist/scss/variables";
@import "@vahak/core/dist/scss/functions";

.disclaimer {
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.verificationAction {
    background-color: $grey-150;
    border-radius: 16px;

    display: flex;
    flex-direction: column;
    gap: 10px;

    position: relative;
    .row1 {
        display: flex;
        gap: 10px;
        position: relative;
        left: -9px;
        padding: 16px 16px 0 0;
        .content {
            display: flex;
            flex-direction: column;
            color: $grey-900;
        }
    }
    .row2 {
        padding: 0px 20px 26px 20px;
        display: flex;
        flex-direction: column;
        gap: 6px;
        .ctaRow {
            display: flex;
            gap: 12px;
        }
    }
}

.list {
    margin: 0;
    margin-top: 12px;
    list-style-type: none;
    padding: 0;

    display: flex;
    flex-direction: column;
    gap: 5px;

    li {
        margin-left: 8px;
        display: grid;
        grid-template-columns: max-content auto;
        column-gap: 8px;
        > svg {
            margin-top: 4px;
        }
    }
}

//Small Screen only
@media (max-width: $mobileBreakpoint) {
}
