@import "@vahak/core/dist/scss/variables";
@import "@vahak/core/dist/scss/functions";

.titleWrapper {
    padding: 24px 24px 0 24px;

    .refetchBtn {
        background-color: #ffffff;
        border: 2px solid #1053ff;
        color: #1053ff;
        border-radius: 50% !important;

        span {
            font-size: 30px;
            line-height: 100%;
        }
    }
}

.container {
    .main {
        min-width: 450px;
        max-height: 400px;
        overflow: scroll;
        position: relative;
    }
    .btnWrapper {
        position: sticky;
        bottom: 0;
    }
}

.cardWrapper {
    cursor: pointer;
    .imageWrapper {
        width: 70px;
        height: 68px;
        border: 1px solid #e3e3e3;
        border-radius: 8px;
        flex: none;

        .status {
            width: 100%;
            background-color: #0f863c;
            color: white;
            border-radius: 0 0 8px 8px;
            text-align: center;

            &.expired {
                background-color: #383838;
            }
        }
    }
}

//Desktop Screen only
@media (min-width: $desktopBreakpoint) {
    .container {
        .main {
            min-height: 400px;
        }
    }
}

// Mid screen
@media (min-width: $mobileBreakpoint) and (max-width: $desktopBreakpoint) {
    .container {
        .main {
            min-height: 400px;
        }
    }
}

//Small Screen only
@media (max-width: $mobileBreakpoint) {
    .container {
        // height: 100%;
        .main {
            min-width: unset;
            max-height: unset;
            padding-bottom: 50px;
        }
        .btnWrapper {
            background-color: white;
            height: 70px;
            position: fixed;
            bottom: 0;
            width: 100%;
            left: 0;
            right: 0;
            padding: 0 16px;
        }
    }
}
