@import "@vahak/core/dist/scss/variables";
@import "@vahak/core/dist/scss/functions";

.main {
    border: 2px solid $grey-200;
    border-radius: 6px;
    .expectedPrice {
        background-color: $green-100;
        padding: 10px 22px;

        .label {
            color: $grey-600;
            font-size: calcRem(14);
            line-height: calcRem(21);
            font-weight: 600;
            font-family: var(--font-medium);
        }
        .value {
            color: $green-600;
            font-size: calcRem(16);
            font-weight: 700;
        }
    }

    .inputContainer {
        padding: 30px 22px;

        .row1 {
            display: flex;
            align-items: center;
        }
        .textInput {
            flex: 1;
            display: flex;
            flex-direction: column;
            gap: 10px;
            .label {
                font-size: calcRem(13);
                line-height: calcRem(18);
                color: $grey-700;
                font-family: var(--font-medium);
                .required {
                    color: $red;
                }
                white-space: nowrap;
            }
            .bold {
                font-weight: 700;
                font-family: var(--font-semiBold);
            }
            .prefixWrapper {
                display: flex;
                align-items: center;
                gap: 8px;
                .textStyle {
                    @extend .bold;
                    font-size: calcRem(24);
                    color: $grey-1000;
                    height: 100%;
                }
                .prefix {
                    @extend .textStyle;
                }
                [data-input-wrapper] {
                    padding: 0;
                    height: 100%;
                    width: 100%;
                    border: none !important;
                    [data-input-prefix],
                    input {
                        @extend .textStyle;
                        padding: 0;
                        width: 100%;
                        &::placeholder {
                            @extend .bold;
                            font-size: calcRem(24);
                        }
                    }
                }
            }
        }
        .info {
            border-left: 2px solid $grey-200;
            padding: 8px 12px;
            display: flex;
            flex-direction: column;
        }
    }
}

//Small Screen only
@media (max-width: $mobileBreakpoint) {
    .main {
        .expectedPrice {
            .label {
                font-size: calcRem(13);
                line-height: calcRem(21);
            }
            .value {
                font-size: calcRem(13);
            }
        }

        .inputContainer {
            padding: 30px 16px;
            .textInput {
                .prefixWrapper {
                    [data-input-wrapper] {
                        background-color: transparent;
                    }
                }
            }
        }
    }
}
