@import "@vahak/core/dist/scss/functions";
@import "@vahak/core/dist/scss/variables";

.main {
    display: inline-flex;
    align-items: center;
    gap: 10px;
    padding: 10px 15px;
    border-radius: 4px;
    cursor: pointer;
}

@media (max-width: $mobileBreakpointMaxWidth) {
    .main {
        svg {
            fill: #000;
        }
        label {
            color: #000;
        }
    }
}

@media (min-width: $desktopBreakpointMinWidth) {
}
