@import "@vahak/core/dist/scss/functions";
@import "@vahak/core/dist/scss/variables";

.main {
    display: flex;
    gap: 8px;
    align-items: center;
    cursor: pointer;

    .text {
        font-size: 13px;
        line-height: 16px;
        color: #2355fc;
    }
}

@media (max-width: $mobileBreakpointMaxWidth) {
}

@media (min-width: $desktopBreakpointMinWidth) {
}
