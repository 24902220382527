@import "@vahak/core/dist/scss/variables";
@import "@vahak/core/dist/scss/functions";

//Small Screen only
@media (max-width: $mobileBreakpoint) {
    .main {
        [data-help-desk-label] {
            color: $blue;
        }
        &.responsive {
            [data-help-desk-wrapper] {
                justify-content: center;
                align-items: center;
                padding: 4px;
                border: 2px solid $grey-700;
                border-radius: 20px;
                height: calcRem(32);
                width: calcRem(45);
                svg,
                path {
                    max-width: 100%;
                    fill: $grey-700;
                }
            }
        }
    }
}
