@import "@vahak/core/dist/scss/variables";
@import "@vahak/core/dist/scss/functions";

.main {
    // padding: 100px 0 45px 52px;
}

//Desktop Screen only
@media (min-width: $desktopBreakpoint) {
}

// Mid screen
@media (min-width: $mobileBreakpoint) and (max-width: $desktopBreakpoint) {
}

//Small Screen only
@media (max-width: $mobileBreakpoint) {
    .main {
        .title {
            display: none;
        }

        .expectedPriceWrapper {
            margin-top: 16px;
        }
    }
}
